import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import {
  FooterSection,
  FooterContainer,
  FooterGrid,
  LeftGrid,
  RightGrid,
  LinkMenu,
  MenuTitle,
  MenuItem,
  ListLink,
  SocialGroup,
  SocialWrapper,
  Copyright,
  About,
} from "./styledElements"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      branding: file(relativePath: { eq: "text_logo.png" }) {
        childImageSharp {
          fixed(width: 185) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <FooterSection>
      <FooterContainer maxWidth="lg" disableGutters={true}>
        <FooterGrid container spacing={0}>
          <LeftGrid item xs={12} sm={6}>
            <Link to="/">
              <Img
                fixed={data.branding.childImageSharp.fixed}
                alt={siteTitle}
              />
            </Link>
            <About>
              We are one of the most comprehensive exterminating companies out
              there when it comes to tackling termites. Our service is always
              professional and friendly, and you always know what you are
              getting when it comes to our methods. Our transparency allows you
              a glimpse into what we do, how we operate, and how effective our
              treatments are.
            </About>
            <SocialGroup>
              <SocialWrapper
                href="https://www.facebook.com/UnitechPestAndTermite"
                target="_blank"
              >
                <FacebookIcon fontSize={"inherit"} />
              </SocialWrapper>
              <SocialWrapper
                href="https://www.instagram.com/UnitechPestAndTermite"
                target="_blank"
              >
                <InstagramIcon fontSize={"inherit"} />
              </SocialWrapper>
              <SocialWrapper
                href="https://twitter.com/unitechpestcom/"
                target="_blank"
              >
                <TwitterIcon fontSize={"inherit"} />
              </SocialWrapper>
              <SocialWrapper
                href="https://www.linkedin.com/company/unitech-pest-and-termite/about/"
                target="_blank"
              >
                <LinkedInIcon fontSize={"inherit"} />
              </SocialWrapper>
            </SocialGroup>
          </LeftGrid>
          <RightGrid item xs={12} sm={6}>
            <LinkMenu>
              <MenuTitle>Quick Links</MenuTitle>
              <ListLink to="https://portal.gorilladesk.com/unitechpest">
                <MenuItem>Account</MenuItem>
              </ListLink>
              <ListLink to="https://www.stlouisbedbugs.com">
                <MenuItem>Bed Bugs</MenuItem>
              </ListLink>
              <ListLink to="https://www.stlouispestexterminator.com">
                <MenuItem>Unitech Exterminator</MenuItem>
              </ListLink>
              <ListLink to="/appoinments">
                <MenuItem>Appoinments</MenuItem>
              </ListLink>
            </LinkMenu>
            <LinkMenu>
              <MenuTitle>Legal</MenuTitle>
              <ListLink to="/">
                <MenuItem>GDPR</MenuItem>
              </ListLink>
              <ListLink to="/">
                <MenuItem>Terms of Service</MenuItem>
              </ListLink>
              <ListLink to="/">
                <MenuItem>Privacy Policy</MenuItem>
              </ListLink>
            </LinkMenu>
          </RightGrid>
        </FooterGrid>
      </FooterContainer>
      <Copyright>
        © {new Date().getFullYear()} {siteTitle}
      </Copyright>
    </FooterSection>
  )
}

export default Footer
