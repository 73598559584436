import React, {useState} from "react"

import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import LaunchIcon from "@material-ui/icons/Launch"

import {
  Highlight,
  TestimonialSlider,
  Testimonial,
  ReviewTitle,
  QuoteContainer,
  Quote,
  ReadMore,
  CustomerInfoAndControlsContainer,
  Controls,
  ControlButton,
  CustomerInfo,
  CustomerProfilePicture,
  CustomerTextInfo,
  CustomerName,
  CustomerTitle,
} from "../styledElements"

const Carousel = ({ testimonials, name }) => {

  const [sliderRef, setSliderRef] = useState(null);

  const settings = {
    ref: setSliderRef,
    arrows: false,
    lazyLoad: true,
    autoplay: true,
    swipeToSlide: true,
    fade: true,
  }

  return (
    <TestimonialSlider  {...settings}>
      {testimonials.map(testimonial => (
        <Testimonial key={testimonial.id}>
          <ReviewTitle>{name}</ReviewTitle>
          <QuoteContainer>
            <Quote>
              <Highlight textColor={props => props.theme.colors.dark} bold>
                {testimonial.user.name} said
              </Highlight>{" "}
              "{testimonial.text}"
            </Quote>
            <ReadMore href={testimonial.url} target="_blank">
              <LaunchIcon style={{ marginRight: 4, fontSize: 18 }} />
              Read more
            </ReadMore>
          </QuoteContainer>
          <CustomerInfoAndControlsContainer>
            <CustomerInfo>
              <CustomerProfilePicture
                src={
                  testimonial.user.image_url
                    ? testimonial.user.image_url
                    : require("../../images/user_avatar.png")
                }
                alt={testimonial.user.name}
              />
              <CustomerTextInfo>
                <CustomerName>{testimonial.user.name}</CustomerName>
                <CustomerTitle>Customer</CustomerTitle>
              </CustomerTextInfo>
            </CustomerInfo>
            <Controls>
              <ControlButton onClick={sliderRef?.slickPrev}>
                <ArrowBackIcon fontSize={"inherit"} />
              </ControlButton>
              <ControlButton onClick={sliderRef?.slickNext}>
                <ArrowForwardIcon fontSize={"inherit"} />
              </ControlButton>
            </Controls>
          </CustomerInfoAndControlsContainer>
        </Testimonial>
      ))}
    </TestimonialSlider>
  )
}

export default Carousel
