import React from "react"
import Img from "gatsby-image"

import {
  GridContainer,
  CardContainer,
  CardContentInfo,
  CardTitle,
  CardInfo,
  CardLinkContainer,
  CardLink,
  CardImage,
} from "../styledElements"

const Card = ({ image, title, text, link, alt }) => {
  return (
    <GridContainer item xs={12} sm={8} md={4}>
      <CardContainer>
        <CardImage>
          <Img fluid={image.childImageSharp.fluid} alt={alt} />
        </CardImage>
        <CardContentInfo>
          <CardTitle>{title}</CardTitle>
          <CardInfo>{text}</CardInfo>
        </CardContentInfo>
        <CardLinkContainer>
          <CardLink target="_blank" href={link}>
            Find Out More
          </CardLink>
        </CardLinkContainer>
      </CardContainer>
    </GridContainer>
  )
}

export default Card
