import Img from "gatsby-image"
import React from "react"
import Carousel from "./utils/Carousel"

import { useStaticQuery, graphql } from "gatsby"
import {
  TestimonialsSection,
  TestimonialsContainer,
  TestimonialsGrid,
  TestimonialsLeftGrid,
  TestimonialsRightGrid,
  SectionName,
  InfoText,
  Title,
  Highlight,
} from "./styledElements"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      yelp {
        business(id: "unitech-pest-and-termite-hazelwood") {
          name
          reviews {
            id
            text
            url
            rating
            user {
              image_url
              name
            }
          }
        }
      }
      testimonial: file(relativePath: { eq: "testimonials.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      userAvatar: file(relativePath: { eq: "user_avatar.png" }) {
        childImageSharp {
          fixed(width: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const defaultTestimonials = [
    {
      id: "outSQRsjDDNFdmBRF1aiaAIOg",
      rating: 5,
      text:
        "We used this company for the first time last week.  We wanted to use a local company, and I'm so happy we did.  The lady in the office was kind and professional, and the owner, was wonderful.  Easy to schedule and work with. Would highly recommend.",
      url: null,
      user: {
        image_url: null,
        name: "Lauren K.",
      },
    },
    {
      id: "outSQRsjNO11oI4F1aiaAIOg",
      rating: 4,
      text:
        "I scheduled an appointment during my lunch hour and it couldn't have gone more smoothly, great communication and excellent customer service, and reasonably priced, highly recommend",
      url: null,
      user: {
        image_url:
          "https://s3-media0.fl.yelpcdn.com/photo/vcgl1eb4XBvw6oxSLGbcMw/60s.jpg",
        name: "Joseph W.",
      },
    },
  ]

  let testimonials = data.yelp.business.reviews

  if (!testimonials || testimonials.length === 0)
    testimonials = defaultTestimonials

  return (
    <TestimonialsSection>
      <TestimonialsContainer>
        <TestimonialsGrid container spacing={0}>
          <TestimonialsLeftGrid item xs={12} sm={12} md={6}>
            <Img
              fluid={data.testimonial.childImageSharp.fluid}
              alt="Webpage displaying reviews"
            />
          </TestimonialsLeftGrid>
          <TestimonialsRightGrid item xs={12} sm={12} md={6}>
            <SectionName
              textColor={props => props.theme.colors.primary2}
              mb="0.5"
            >
              Testimonials
            </SectionName>
            <Title mb="1.2">
              Our customers{" "}
              <Highlight textColor={props => props.theme.colors.primary2}>
                love us
              </Highlight>
            </Title>
            <InfoText>
              Here is what some of our amazing customers are saying about our
              pest and control professional services.
            </InfoText>
            <Carousel
              testimonials={testimonials}
              name={data.yelp.business.name}
            />
          </TestimonialsRightGrid>
        </TestimonialsGrid>
      </TestimonialsContainer>
    </TestimonialsSection>
  )
}

export default Testimonials
