import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import {
  AboutContainer,
  AboutGrid,
  AboutLeftGrid,
  AboutSection,
  AboutRightGrid,
  SectionName,
  Title,
  AboutInfo,
  AboutData,
  AboutDataInfo,
  DataNumber,
  Highlight,
} from "./styledElements"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutUs: file(relativePath: { eq: "about_us.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <AboutSection>
      <AboutContainer>
        <AboutGrid container spacing={0}>
          <AboutLeftGrid item xs={12} sm={12} md={6}>
            <Img fluid={data.aboutUs.childImageSharp.fluid} alt="Personal Data Identification" />
          </AboutLeftGrid>
          <AboutRightGrid item xs={12} sm={12} md={6}>
            <SectionName
              textColor={props => props.theme.colors.primary2}
              mb="1"
            >
              Our Track Record
            </SectionName>
            <Title mb="3">
              We have been doing this for over{" "}
              <Highlight textColor={props => props.theme.colors.primary2}>
                12 Years
              </Highlight>
            </Title>
            <AboutInfo>
              Unitech Pest and Bed Bug Services is a full service, professional
              pest management company located in St. Louis, Missouri. We stand
              prepared to eliminate unwanted pests in your home or business. Our
              fast, courteous and professional staff will listen to your
              concerns and promptly answer any questions you have regarding our
              methods of pest removal and the costs associated with it.
            </AboutInfo>
            <AboutData>
              <AboutDataInfo>
                1468+ <DataNumber> Clients</DataNumber>
              </AboutDataInfo>
              <AboutDataInfo>
                4.9 <DataNumber> Google rating</DataNumber>
              </AboutDataInfo>
            </AboutData>
          </AboutRightGrid>
        </AboutGrid>
      </AboutContainer>
    </AboutSection>
  )
}

export default Services
