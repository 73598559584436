import React from 'react'
import { Player as VideoPlayer, BigPlayButton } from 'video-react';

import "./video-react.css"

const Player = () => {
  return (
    <VideoPlayer
    src={require("../../../videos/Testimonial.mp4")}
    poster={require("../../../images/video_snapshot.png")}
    preload="auto"
    fluid>
    <BigPlayButton position="center" />
  </VideoPlayer>
  )
}

export default Player
