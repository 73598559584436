import { Link } from "gatsby"
import styled, { css } from "styled-components"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

export const ContactSection = styled.section`
  position: relative;
  background: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.light1};
  overflow: hidden;
`

export const IconOne = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 22rem;
  transform: translateY(15rem);
`

export const IconTwo = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 22rem;
  transform: translateY(-18rem);
`

export const ContactGrid = styled(Grid)`
  display: flex;
`

export const ContactRightGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > * {
    flex-grow: 1;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    padding-top: 5rem;
  }
`

export const ContactLeftGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ContactContainer = styled(Container)`
  padding: 3rem 1rem;
`

export const SectionName = styled.p`
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 2.2rem;

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
    `};

  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}rem;
    `};
`

export const Title = styled.h1`
  font-size: 4.6rem;
  font-weight: 700;
  margin-bottom: 4.2rem;
  line-height: 5rem;

  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}rem;
    `};
`
export const LinkWrapper = styled(Link)`
  align-self: center;
`

export const Button = styled.button`
  font-size: 1.9rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.7rem 1rem;
  background: ${props => props.theme.colors.light1};
  color: ${props => props.theme.colors.dark};
  border-radius: 0.4rem;
  border: 0.6rem solid ${props => props.theme.colors.light1};
  outline: none;
  cursor: pointer;
  align-self: center;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
  transition: background 250ms;

  &:hover,
  &:focus {
    background: transparent;
    color: ${props => props.theme.colors.light1};
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
`
