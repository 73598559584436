import styled from "styled-components"
import { Container, Grid } from "@material-ui/core"

export const AboutSection = styled.section``

export const AboutContainer = styled(Container)`
  padding: 3rem 1rem;
`

export const AboutGrid = styled(Grid)``

export const AboutLeftGrid = styled(Grid)`
  align-items: center;
  align-self: center;
  justify-content: center;
  text-align: center;
  align-content: center;
  display: flex;

  & > * {
    flex-grow: 1;
  }

  @media ${props => props.theme.breakpoints.tablet} {
    display: flex;
  }
  @media ${props => props.theme.breakpoints.mobile} {
    display: flex;
    align-self: center;
    padding-bottom: 2rem;
  }
`
export const AboutRightGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const AboutInfo = styled.p`
  width: 90%;
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: ${props => props.theme.colors.dark};
  margin-bottom: 2rem;
`
export const AboutData = styled.div`
  display: flex;
`
export const AboutDataInfo = styled.div`
  font-family: "Rubik";
  font-size: 1.7rem;
  display: flex;
  flex-direction: column;
  font-weight: 600;

  &:first-of-type {
    margin-right: 3.5rem;
  }
`
export const DataNumber = styled.div`
  font-family: "Rubik";
  font-weight: 500;
  font-size: 1.4rem;
  margin-top: 0.2rem;

  color: ${props => props.theme.colors.primary2};
`
