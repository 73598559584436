import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import {
  ContactSection,
  IconOne,
  IconTwo,
  ContactContainer,
  ContactGrid,
  ContactLeftGrid,
  ContactRightGrid,
  SectionName,
  Title,
  LinkWrapper,
  Button,
} from "./styledElements"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      contact: file(relativePath: { eq: "contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      leftIcon: file(relativePath: { eq: "contact-left-icon.png" }) {
        publicURL
      }
      rightIcon: file(relativePath: { eq: "contact-right-icon.png" }) {
        publicURL
      }
    }
  `)

  return (
    <ContactSection>
      <IconOne src={data.leftIcon.publicURL} alt="Round shape" />
      <IconTwo src={data.rightIcon.publicURL} alt="Roundish shape" />
      <ContactContainer>
        <ContactGrid container spacing={0}>
          <ContactLeftGrid item xs={12} sm={6}>
            <SectionName>Contact Us</SectionName>
            <Title>Book a no obligation, FREE inspection!</Title>
            <LinkWrapper to="/appoinments">
              <Button>book free inspection</Button>
            </LinkWrapper>
          </ContactLeftGrid>
          <ContactRightGrid item xs={12} sm={6}>
            <Img
              fluid={data.contact.childImageSharp.fluid}
              alt="Man sending a mail"
            />
          </ContactRightGrid>
        </ContactGrid>
      </ContactContainer>
    </ContactSection>
  )
}

export default Contact
