import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import TodayIcon from "@material-ui/icons/Today"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import {
  HeaderSection,
  HeaderContainer,
  Nav,
  ContactButton,
  PhoneButton,
  Line,
  ButtonWrapper,
  ContactWrapper,
  LineWrapper,
  Text,
} from "./styledElements"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      branding: file(relativePath: { eq: "branding.png" }) {
        childImageSharp {
          fixed(width: 175) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [headerToggle, setHeaderToggle] = useState(false)

  const handleHeaderTogle = () => {
    if (window.scrollY >= 100) {
      setHeaderToggle(true)
    } else {
      setHeaderToggle(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleHeaderTogle)

    return () => {
      window.removeEventListener("scroll", handleHeaderTogle)
    }
  }, [headerToggle])

  return (
    <HeaderSection toggle={headerToggle}>
      <HeaderContainer maxWidth="lg" disableGutters={true}>
        <Link to="/">
          <Img fixed={data.branding.childImageSharp.fixed} alt={siteTitle} />
        </Link>
        <Nav>
          <ContactWrapper>
            <LineWrapper>
              <TodayIcon style={{ fontSize: 20 }} />{" "}
              <Text>Mon - Fri 8:00 am - 5:00 pm</Text>
            </LineWrapper>
            <LineWrapper>
              <MailOutlineIcon style={{ fontSize: 19 }} />{" "}
              <Text>info@unitechpest.com</Text>
            </LineWrapper>
          </ContactWrapper>
          <Line />
          <ButtonWrapper>
            <PhoneButton href="tel:314-558-4847">(314) 558 - 4847</PhoneButton>
            <Link to="/appoinments">
              <ContactButton>Get a quote</ContactButton>
            </Link>
          </ButtonWrapper>
        </Nav>
      </HeaderContainer>
    </HeaderSection>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
