import Slider from "react-slick"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

import styled, { css } from "styled-components"

import "slick-carousel/slick/slick.css"

export const TestimonialsSection = styled.section`
  background: ${props => props.theme.colors.light1};
  color: ${props => props.theme.colors.dark};
`

export const TestimonialsGrid = styled(Grid)``

export const TestimonialsLeftGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > * {
    flex-grow: 1;
  }

  @media ${props => props.theme.breakpoints.laptop} {
    padding-right: 1.5rem;
  }

  @media only screen and (max-width: 960px) {
    justify-content: center;
    margin-bottom: 2rem;
    padding-right: 0;
  }

  @media ${props => props.theme.breakpoints.tablet} {
    justify-content: center;
    margin-bottom: 2rem;
  }
`

export const TestimonialsRightGrid = styled(Grid)``

export const TestimonialsContainer = styled(Container)`
  padding: 3rem 1rem;
`

export const Highlight = styled.span`
  color: ${props => props.theme.colors.dark};

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
    `};

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `};
`

export const InfoText = styled.p`
  font-size: 1.7rem;
`

export const TestimonialSlider = styled(Slider)`
  padding-top: 5rem;

  &:focus {
    outline: none;
  }
`

export const Testimonial = styled.div`
  width: 90%;
  min-height: 26rem;
`
export const ReviewTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
`
export const QuoteContainer = styled.div`
  margin-bottom: 3.5rem;
`
export const Quote = styled.blockquote`
  font-size: 1.7rem;
  margin-bottom: 1rem;
`

export const ReadMore = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  font-weight: 700;
  color: ${props => props.theme.colors.primary2};
`

export const CustomerInfoAndControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Controls = styled.div`
  display: flex;
`

export const ControlButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  padding: 0.8rem;
  background: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.light1};
  border-radius: 50%;
  outline: none;
  border: none;

  &:first-of-type {
    margin-right: 2rem;
  }

  &:hover {
    box-shadow: 0 0.5rem 2rem -0.2rem rgba(0, 0, 0, 0.2);
  }
`

export const CustomerInfo = styled.div`
  display: flex;
  align-items: center;
`
export const CustomerProfilePicture = styled.img`
  width: 6.5rem;
  border-radius: 50%;
`
export const CustomerTextInfo = styled.div`
  margin-left: 1rem;
`
export const CustomerName = styled.h5`
  font-size: 1.7rem;
  padding-bottom: 0.6rem;
`
export const CustomerTitle = styled.p`
  font-size: 1.7rem;
`
