import styled, { css } from "styled-components"
import Container from "@material-ui/core/Container"

export const HeaderSection = styled.header`
  width: 100%;
  height: 9.5rem;
  background: ${props => props.theme.colors.light1};
  box-shadow: 0 0.5rem 2rem -0.2rem rgba(0, 0, 0, 0.1);

  ${({ toggle }) =>
    toggle &&
    css`
      position: fixed;
      z-index: 999;
    `}
`

export const HeaderContainer = styled(Container)`
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`

export const Nav = styled.nav`
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  display: flex;

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
  }
`

export const ContactButton = styled.button`
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.55rem 0.7rem;
  background: ${props => props.theme.colors.accent1};
  color: ${props => props.theme.colors.dark};
  border-radius: 0.5rem;
  border: 0.5rem solid ${props => props.theme.colors.accent1};
  outline: none;
  cursor: pointer;
  transition: background 250ms;

  &:hover,
  &:focus {
    background: transparent;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    width: 14.5rem;
    margin-top: 0.5rem;
    font-size: 1.4rem;
  }
`

export const PhoneButton = styled.a`
  position: relative;
  height: fit-content;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.8rem 1rem;
  margin-right: 1.2rem;
  background: transparent;
  color: ${props => props.theme.colors.primary1};
  border-radius: 0.5rem;
  border: 0.1rem solid ${props => props.theme.colors.dark};
  transition: 250ms;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 2rem ${props => props.theme.colors.dark};
    color: ${props => props.theme.colors.light1};
  }

  @media ${props => props.theme.breakpoints.mobile} {
    margin-right: 0;
  }
`

export const Line = styled.div`
  background: ${props => props.theme.colors.dark};
  height: 6rem;
  width: 0.1rem;
  margin: 0 2.2rem;

  @media ${props => props.theme.breakpoints.tablet} {
    display: none;
  }
`

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;

  @media ${props => props.theme.breakpoints.laptop} {
    margin-right: 0;
  }

  @media ${props => props.theme.breakpoints.tablet} {
    display: none;
  }
`

export const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
`

export const Text = styled.p`
  padding-left: 0.5rem;
`
