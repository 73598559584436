import React, { useState, useEffect } from "react"
import StoreRoundedIcon from "@material-ui/icons/StoreRounded"
import Player from "./utils/Player"

import {
  HeaderOne,
  HeaderTwo,
  Paragraph,
  LinkButton,
  SectionWrapper,
  HeroContainer,
  HeroGrid,
  HeroRightGrid,
  HeroLeftGrid,
  Services,
  ButtonContainer,
  TitleContainer,
  IconWrapper,
} from "./styledElements"

const Hero = () => {
  const [spacingToggle, setSpacingToggle] = useState(false)

  const handleSpacingToggle = () => {
    if (window.scrollY >= 100) {
      setSpacingToggle(true)
    } else {
      setSpacingToggle(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleSpacingToggle)

    return () => {
      window.removeEventListener("scroll", handleSpacingToggle)
    }
  }, [spacingToggle])

  return (
    <SectionWrapper toggle={spacingToggle}>
      <HeroContainer>
        <HeroGrid container spacing={0}>
          <HeroLeftGrid item xs={12} sm={12} md={6}>
            <TitleContainer>
              <Paragraph> Have a bug problem ?</Paragraph>
              <HeaderOne>Hire the best</HeaderOne>
              <HeaderTwo>Pest & Termite control. </HeaderTwo>
            </TitleContainer>
            <ButtonContainer>
              <LinkButton to="/appoinments">Book a Free Inspection</LinkButton>
              <Services to="#Services" target="_blank">
                <IconWrapper>
                  <StoreRoundedIcon fontSize={"inherit"} />
                </IconWrapper>
                View Services
              </Services>
            </ButtonContainer>
          </HeroLeftGrid>
          <HeroRightGrid item xs={12} sm={12} md={6}>
            <Player />
          </HeroRightGrid>
        </HeroGrid>
      </HeroContainer>
    </SectionWrapper>
  )
}

export default Hero
