import { Link } from "gatsby"
import styled from "styled-components"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

export const FooterSection = styled.footer``

export const FooterGrid = styled(Grid)``

export const LeftGrid = styled(Grid)``

export const RightGrid = styled(Grid)`
  display: flex;
  justify-content: center;

  @media ${props => props.theme.breakpoints.tablet} {
    flex-direction: column;
    align-items: center;
  }
  @media ${props => props.theme.breakpoints.mobile} {
    align-items: flex-start;
  }
`

export const FooterContainer = styled(Container)`
  padding: 3rem 1rem;
`

export const About = styled.p`
  width: 78%;
  margin-top: 1.3rem;

  @media ${props => props.theme.breakpoints.tablet} {
    width: 105%;
  }
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`

export const SocialGroup = styled.div`
  display: flex;
  margin: 2.5rem 0 1rem;
`

export const SocialWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.light2};
  border: 0.3rem solid ${props => props.theme.colors.dark};
  border-radius: 50%;
  margin: 0 1rem;
  font-size: 2rem;
  transition: all 250ms;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover,
  &:focus {
    background: transparent;
    transform: translateY(-0.2rem);
    color: ${props => props.theme.colors.dark};
    box-shadow: 0 6px 13px rgba(0, 0, 0, 0.3);
  }
  }
`

export const LinkMenu = styled.ul`
  list-style: none;
  margin-top: 1.1rem;

  &:first-of-type {
    margin-right: 10rem;
  }

  @media ${props => props.theme.breakpoints.laptop} {
    &:first-of-type {
      margin-right: 7rem;
    }
  }
  @media ${props => props.theme.breakpoints.tablet} {
    &:first-of-type {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
    &:nth-child(2) {
      margin-right: 4.5rem;
    }
  }
  @media ${props => props.theme.breakpoints.mobile} {
    margin-top: 2.5rem;
  }
`

export const MenuTitle = styled.li`
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1.3rem;
`

export const MenuItem = styled.li`
  font-size: 1.7rem;
  padding: 0.2rem 0;
`

export const ListLink = styled(Link)`
  color: ${props => props.theme.colors.dark};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

export const Copyright = styled.div`
  background: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.light2};
  text-align: center;
  padding: 1.8rem 0;
`
