import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { Container, Grid } from "@material-ui/core"

export const SectionWrapper = styled.section`
  ${({ toggle }) =>
    toggle &&
    css`
      padding-top: 9.5rem;
    `}

  @media ${props => props.theme.breakpoints.mobile} {
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 0;
  }
`

export const HeroContainer = styled(Container)`
  padding: 6rem 1rem;

  @media ${props => props.theme.breakpoints.tablet} {
    padding: 3rem 1rem;
  }
  @media (min-width: 791px) and (max-width: 959px) {
    padding: 3rem 1rem;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    display: flex;
    align-self: center;
    justify-content: center;
  }
`
export const HeroGrid = styled(Grid)`
  @media ${props => props.theme.breakpoints.tablet} {
    display: flex;
    align-self: center;
    justify-content: center;
  }
  @media (min-width: 960px) and (max-width: 1222px) {
    display: flex;
    align-self: center;
    justify-content: center;
  }
  @media ${props => props.theme.breakpoints.mobile} {
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 0;
  }
`
export const HeroLeftGrid = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 1rem;

  @media ${props => props.theme.breakpoints.tablet} {
    display: flex;
    align-content: space-around;
    justify-content: space-between;
    margin-bottom: 4rem;
  }
  @media (min-width: 791px) and (max-width: 959px) {
    margin-bottom: 4.5rem;
  }
  @media (min-width: 960px) and (max-width: 1222px) {
    margin-bottom: 4.5rem;
  }
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  margin-bottom: 4.5rem;
`

export const Paragraph = styled.p`
  font-weight: 500;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.dark};
  border-left: 0.5rem solid ${props => props.theme.colors.accent1};
  padding: 0.5rem 0.8rem;
  margin-bottom: 2rem;
  transform: translateX(3.6rem);

  @media ${props => props.theme.breakpoints.mobile} {
    transform: translateX(2.2rem);
  }
`
export const HeaderOne = styled.h1`
  font-weight: 700;
  font-size: 5rem;
  color: ${props => props.theme.colors.dark};
  margin-bottom: 0.9rem;
  transform: translateX(3.5rem);

  @media ${props => props.theme.breakpoints.mobile} {
    transform: translateX(2rem);
  }

  @media only screen and (max-width: 370px) {
    font-size: 4.8rem;
  }
`
export const HeaderTwo = styled.h1`
  position: relative;
  font-weight: 700;
  font-size: 4.85rem;
  letter-spacing: -0.1rem;
  color: ${props => props.theme.colors.light1};
  padding: 0.6rem 0;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${props => props.theme.colors.dark};
    clip-path: polygon(1% 100%, 6% 0, 99% 0, 94% 100%);
    z-index: -1;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    font-size: 4.75rem;
  }

  @media only screen and (max-width: 376px) {
    font-size: 4rem;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  transform: translateX(3.6rem);

  @media ${props => props.theme.breakpoints.mobile} {
    flex-direction: column;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    transform: translateX(0.5rem);
  }
`

export const LinkButton = styled(Link)`
  font-weight: 600;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.dark};
  background: ${props => props.theme.colors.accent1};
  padding: 0.8rem 1rem;
  margin-right: 3.5rem;
  border-radius: 0.4rem;
  border: 0.6rem solid ${props => props.theme.colors.accent1};
  outline: none;
  cursor: pointer;
  transition: background 250ms;

  &:hover,
  &:focus {
    background: transparent;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    width: 80%;
    align-self: center;
    margin-bottom: 2rem;
  }
`

export const Services = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.8rem;
  color: ${props => props.theme.colors.dark};
  padding-right: 1rem;

  &:hover,
  &:focus {
    padding: 0 1rem 0 0.2rem;
    border: 0.25rem solid ${props => props.theme.colors.dark};
    border-radius: 8rem;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.12);
  }

  &:hover > *,
  &:focus > * {
    transform: rotate(1turn);
    border: none;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    width: 80%;
    align-self: center;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  padding: 0.6rem;
  border: 0.25rem solid ${props => props.theme.colors.dark};
  border-radius: 50%;
  margin-right: 1.3rem;
  transition: transform 350ms;
`

export const HeroRightGrid = styled(Grid)``
