import React from "react"
import Card from "./utils/Card"

import { graphql, useStaticQuery } from "gatsby"
import { ServiceWrapper, ContainerWrapper } from "./styledElements"

const Services = () => {
  const data = useStaticQuery(graphql`
    query Services {
      residentialSpaces: file(relativePath: { eq: "residential_spaces.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      commercialSpaces: file(relativePath: { eq: "commercial_spaces.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bedBugs: file(relativePath: { eq: "bed_bug.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <ContainerWrapper id="Services">
      <ServiceWrapper container spacing={0}>
        <Card
          image={data.residentialSpaces}
          title="Residential Spaces"
          text=" We offer a variety of exterminating services for your home,
                including eliminating household pests, rodent control, termite
                eradication, bed bug treatment, and much more..."
          link="https://www.stlouispestexterminator.com/residential.html"
          alt="Happy family night"
        />
        <Card
          image={data.commercialSpaces}
          title="Commercial Spaces"
          text="We will inspect and treat interception sites in your Restaurant, Commercial kitchen, Warehouse, and Office to ensure that your facility stays under control..."
          link="https://www.stlouispestexterminator.com/commercial.html"
          alt="Comercial Building"
        />
        <Card
          image={data.bedBugs}
          title="Bed Bugs"
          text="At Unitech Pest and Termite Control, we use a combination of Heat and Chemical Control Methods - to ensure that ALL bed bugs, and their eggs, are killed the first time..."
          link="https://www.stlouisbedbugs.com/"
          alt="Bugs being exterminated"
        />
      </ServiceWrapper>
    </ContainerWrapper>
  )
}

export default Services
// Hellososoosos
